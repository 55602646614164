import React from "react";
import { Box, FormControlLabel, TextField, Typography} from "@mui/material";
import FlexColumn from "../../element/Flex/flexColumn";
import FlexRow from "../../element/Flex/flexRow";


interface IForm {
    formData:any
}

const FormFieldSecond: React.FC<IForm> = ({formData}) => {
    const { formField, values, errors, touched, handleChange } = formData;
    const {materialForm , logicielForm, salaireForm, nbPatientForm, diplomeForm, facturationForm} = formField;
    const { material, logiciel, salaire, nombreOphta, nbPatient, parcours, assistant, facturationByMedecin } = values;

    return(
        
        <Box sx={{ alignItems: "center", margin:"10px 10% 10px 10%"}}>
                <FlexColumn>
                    {/* <TextField id="outlined-basic" label={materialForm.label} variant="outlined" style={{margin:"10px 0 10px 0"}} name={materialForm.name} type={materialForm.text} required
                        onChange={handleChange} value={material}/> */}
                    <TextField id="outlined-basic" label={logicielForm.label} variant="outlined" style={{margin:"10px 0 10px 0"}} name={logicielForm.name} type={logicielForm.type} 
                        onChange={handleChange} value={logiciel}/>
                    <TextField id="outlined-basic" label={salaireForm.label} variant="outlined" style={{margin:"10px 0 10px 0"}} name={salaireForm.name} type={salaireForm.type} 
                        onChange={handleChange} value={salaire}/>
                    <TextField id="outlined-basic" label={nbPatientForm.label} variant="outlined" style={{margin:"10px 0 10px 0"}} name={nbPatientForm.name} type={nbPatientForm.type} 
                        onChange={handleChange} value={nbPatient}/>
                    <Typography variant="subtitle1">{diplomeForm.label}</Typography>
                    <FlexRow>
                        <FormControlLabel
                            control={<input type={diplomeForm.type} name={diplomeForm.name} value={"Oui"} onChange={handleChange}/>}
                            label="Oui"
                            />
                        <FormControlLabel
                            control={<input type={diplomeForm.type} name={diplomeForm.name} value={"Non"} onChange={handleChange}/>}
                            label="Non"
                            />
                        <FormControlLabel
                            control={<input type={diplomeForm.type} name={diplomeForm.name} value={"remplacement"} onChange={handleChange}/>}
                            label="Licence de remplacement"
                            />
                    </FlexRow>

                  {/*   <Typography variant="subtitle1">{facturationForm.label}</Typography>
                    <FlexRow>
                        <FormControlLabel
                            value={facturationByMedecin}
                            // onChange={() => {formik.values.facturationByMedecin = "Par le médecin"}}
                            control={<input type={facturationForm.type} name={facturationForm.name} value={"Par le médecin"} onChange={handleChange} />}
                            label="Par le médecin"
                            />
                        <FormControlLabel
                            value={facturationByMedecin}
                            // onChange={() => {formik.values.facturationByMedecin = "Par l'accueil"}}
                            control={<input type={facturationForm.type} name={facturationForm.name} value={"Par l'accueil"} onChange={handleChange} />}
                            label="Par l'accueil"
                            />
                    </FlexRow> */}
                </FlexColumn>
        </Box>
    );
};


export default FormFieldSecond;

// interface IForm {
//     formik: any;
// }

// const FormFieldSecond: React.FC<IForm> = ({formik}) => {
    
//     return(
        
//         <Box sx={{ alignItems: "center", margin:"10px 10% 10px 10%"}}>
//             <form onSubmit={formik.handleSubmit}>
//                 <FlexColumn>
//                     <TextField id="outlined-basic" label="Matériel" variant="outlined" style={{margin:"10px 0 10px 0"}} name="material" type="material" required
//                         onChange={formik.handleChange} value={formik.values.material}/>
//                     <TextField id="outlined-basic" label="Logiciel" variant="outlined" style={{margin:"10px 0 10px 0"}} name="logiciel" type="logiciel" required
//                         onChange={formik.handleChange} value={formik.values.logiciel}/>
//                     <TextField id="outlined-basic" label="Salaire" variant="outlined" style={{margin:"10px 0 10px 0"}} name="salaire" type="salaire" required
//                         onChange={formik.handleChange} value={formik.values.salaire}/>
//                     <TextField id="outlined-basic" label="Nb d'orthoptiste" variant="outlined" style={{margin:"10px 0 10px 0"}} name="nombreOphta" type="nombreOphta" required
//                         onChange={formik.handleChange} value={formik.values.nombreOphta}/>
//                     <TextField id="outlined-basic" label="Nb de patient" variant="outlined" style={{margin:"10px 0 10px 0"}} name="nbPatient" type="nbPatient" required
//                         onChange={formik.handleChange} value={formik.values.nbPatient}/>
//                     <TextField id="outlined-multiline-static" multiline rows={4} label="Parcour de soin" required
//                         variant="outlined" style={{margin:"10px 0 10px 0"}} name="parcours" type="parcours" 
//                         onChange={formik.handleChange} value={formik.values.parcours}/> 

//                     <Typography variant="subtitle1">Travail aidé par un assistant ? *</Typography>
//                     <FlexRow>
//                         <FormControlLabel
//                             value={formik.values.assistant}
//                             onChange={() => {formik.values.assistant = "Oui"
//                             }}
//                             control={<Checkbox />}
//                             label="Oui"
//                             />
//                         <FormControlLabel
//                             value={formik.values.assistant}
//                             onChange={() => {formik.values.assistant = "Non"}}
//                             control={<Checkbox />}
//                             label="Non"
//                             />
//                     </FlexRow>

//                     <Typography variant="subtitle1">Facturation et cotation faite par le médecin ou à l'accueil ? *</Typography>
//                     <FlexRow>
//                         <FormControlLabel
//                             value={formik.values.facturationByMedecin}
//                             onChange={() => {formik.values.facturationByMedecin = "Par le médecin"}}
//                             control={<Checkbox />}
//                             label="Par le médecin"
//                             />
//                         <FormControlLabel
//                             value={formik.values.facturationByMedecin}
//                             onChange={() => {formik.values.facturationByMedecin = "Par l'accueil"}}
//                             control={<Checkbox />}
//                             label="Par l'accueil"
//                             />
//                     </FlexRow>
//                 </FlexColumn>
//             </form>
//         </Box>
//     );
// };


// export default FormFieldSecond;