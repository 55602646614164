import React from "react";
import { Box, TextField} from "@mui/material";
import FlexColumn from "../../element/Flex/flexColumn";
import FlexRow from "../../element/Flex/flexColumn";

interface IForm {
    formData: any;
}

const FormFieldFirst: React.FC<IForm> = ({formData}) => {
    const { formField, values, errors, touched, handleChange } = formData;
    const { email, lastName, firstName, address, cityForm, postCodeForm, phoneForm, speciality} = formField;

     const {
        specialityV,
        lastNameV,
        firstNameV,
        mail,
        adresse,
        city,
        postCode,
        telephone,
    } = values

    return(
        <Box sx={{ alignItems: "center", margin:"10px 10% 10px 10%"}}>
            <FlexColumn>
                <FlexColumn>
                    <TextField id="outlined-basic" label={speciality.label} variant="outlined" style={{margin:"10px 0 10px 0"}}name={speciality.name} type={speciality.type} required onChange={handleChange} value={specialityV} error={errors.nameCenter}/> 
                    <TextField id="outlined-basic" label={firstName.label} variant="outlined" style={{margin:"10px 0 10px 0"}}name={firstName.name} type={firstName.type} required onChange={handleChange} value={firstNameV} error={errors.nameCenter}/> 
                    <TextField id="outlined-basic" label={lastName.label} variant="outlined" style={{margin:"10px 0 10px 0"}} name={lastName.name} type={lastName.type} required value={lastNameV}  onChange={handleChange}/>
                    <TextField id="outlined-basic" label={address.label} variant="outlined" style={{margin:"10px 0 10px 0"}} name={address.name} type={address.type} required value={adresse} onChange={handleChange}/>
                    <FlexRow style={{width:'100%'}}>
                        <TextField id="outlined-basic" label={cityForm.label} variant="outlined" style={{margin:"10px 10px 10px 0", width:'100%'}} name={cityForm.name} type={cityForm.type} required
                            onChange={handleChange} value={city}/>
                        <TextField id="outlined-basic" label={postCodeForm.label} variant="outlined" style={{margin:"10px 0 10px 0", width:'100%'}} name={postCodeForm.name} type={postCodeForm.type} required
                            onChange={handleChange} value={postCode}/>
                    </FlexRow>
                    <FlexRow style={{width:'100%'}}>
                        <TextField id="outlined-basic" 
                        label={email.label}
                        variant="outlined"
                        style={{margin:"10px 10px 10px 0", width:'100%'}}
                        name={email.name}
                        type={email.type}
                        onChange={handleChange}
                        required
                        value={mail}
                        error={errors.email}
                    />

                        <TextField id="outlined-basic" label={phoneForm.label} variant="outlined" style={{margin:"10px 0 10px 0", width:'100%'}} name={phoneForm.name} type={phoneForm.type} required
                            onChange={handleChange} value={telephone}/>
                    </FlexRow>
                </FlexColumn>
            </FlexColumn>
        </Box>
    );
};


export default FormFieldFirst;

