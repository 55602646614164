import './App.css';
import {  Box, Fab } from '@mui/material';
import { PhoneInTalk } from '@mui/icons-material';
import { Footer } from './component/footer';
import { PlanningScreen } from './screen/Planning';
import { AppBar } from './component/navbar';
import styled from 'styled-components';

const H1 = styled.h1`
  margin:10px 0 -10px 0;
  @media screen and (max-width: 650px) {
    margin:10px 0 -10px 25px;
  }
`



function App() {
  return (
    <>
      <div className="App" >
          <Box sx={{position:"fixed", right:'0', top:"260px"}}>
            <Fab style={{marginRight:'10px!important', backgroundColor:'#120FBB'}}color="primary" aria-label="add" href="tel:0650507317">
              <PhoneInTalk />
            </Fab>
          </Box>
          <AppBar />
          <div style={{display:'flex',flexDirection:'column', alignItems:'center', backgroundColor:"#FFFFFF", borderRadius:"20px", marginTop:"100px", border:"10px solid #120FBB"}}>
            <H1 style={{fontFamily:"Orienta"}}>Formulaire d'information</H1>
            <h2 style={{fontFamily:"Orienta"}}>Praticien</h2>
            <PlanningScreen />
          </div>
        </div>
      <Footer/>
    </>
  );
}

export default App;
