import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import data from '../../data/fr.json';
import { format, startOfISOWeek } from 'date-fns';
import { ICreneaux } from '../../data/data';
import DayCell from './dayCell';
import { TimerOutlined } from '@mui/icons-material';

interface IForm {
  formData: any;
  setForm: any;
  form: ICreneaux[];
  post: (form: ICreneaux[]) => void;
}

const styleModal = {
  borderRadius: '20px',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '3px solid #120FBB',
  boxShadow: 24,
  p: 4,
};

const Planning: React.FC<IForm> = ({setForm, form, formData, post}) => {
  const [dateStart] = useState(format(startOfISOWeek(new Date()), "dd"));
  const [dateWeek, setDateWeek] = useState<string[]>([]);
  // const [arrayCrenaux, setArrayCreneaux] = useState<ICreneaux[] | undefined>([]);

  const { formField, values, errors, touched, handleChange } = formData;

  const selectCrenaux = (date: string, crenaux: string, recurrenceDate: boolean, id: number) => {
    values.date = date;
    values.crenaux = crenaux;
    values.recurrenceDate = recurrenceDate;

    const {firstName, lastName, adresse, postCode, city, telephone, mail, material, logiciel, salaire, nombreOphta, nbPatient, parcours, facturationByMedecin, assistant, diplome, speciality} = values
    const data: ICreneaux = {
      id,
      lastName,
      firstName,
      adresse,
      postCode,
      city,
      dispo: true,
      date,
      crenaux,
      recurrenceDate,
      telephone,
      mail,
      material,
      logiciel,
      salaire,
      nbPatient,
      diplome,
      speciality,
      periodStart: "",
      periodEnd: ""
    }

    if(!form) {
      setForm([data])
    }
    if(form) {
      // const creneauxElem = form.find(elem => elem.date === data.date && elem.crenaux === data.crenaux);      
      // if(creneauxElem){
      //   console.log(form.filter(elem => elem.id !== creneauxElem.id));
        
      //   // setForm(arrayCrenaux.filter(elem => elem.date !== creneauxElem.date && elem.crenaux !== creneauxElem.crenaux));
      // }
      // else {
        setForm([...form, data]);
      // }
    }
  }

  
  const deleteCrenaux = (id: number) => {
    const creneauxElem = form.find(elem => elem.id === id);
    if(creneauxElem) {
      const f = form.filter(elem => elem.id !== creneauxElem.id)
      setForm(f);
    }
  }

  useEffect(() => {
    let arrDate = [];
    const year = new Date().getFullYear();
    const month = Number(format(new Date(), 'MM')) - 1;

    for(let i = 0; i < 60; i++ ){
      let result =(parseInt(dateStart)) + i;
      var formattedNumber = ("0" + result).slice(-2);
      // 
      arrDate.push(format(new Date(year, month, result), `yyyy-MM-${formattedNumber}`));
  }
  setDateWeek(arrDate); 
  // console.log(arrDate);
  }, [])

  //modal 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // date modal 
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const {firstName, lastName, adresse, postCode, city, telephone, mail, material, logiciel, salaire, nombreOphta, nbPatient, parcours, facturationByMedecin, assistant, diplome, speciality} = values
    const data: ICreneaux = {
      id: 0,
      lastName,
      firstName,
      adresse,
      postCode,
      city,
      dispo: true,
      date: "",
      crenaux: "",
      recurrenceDate: false,
      telephone,
      mail,
      material,
      logiciel,
      salaire,
      nbPatient,
      diplome,
      speciality,
      periodStart: startDate,
      periodEnd: endDate
    }
    // console.log(data);
    // // setForm([data]);
    // console.log(form);
    
    // // Effectuer les traitements nécessaires avec les valeurs startDate et endDate
    // console.log('Période sélectionnée :', startDate, ' - ', endDate);

    // // Réinitialiser les valeurs des inputs
    // setStartDate('');
    // setEndDate('');
    post([data]);
    // handleClose();
  };

  return (
    <div style={{alignItems: "center", margin:"40px 10% 10px 10%"}}>
      <div>
        <Typography variant="h6">{data.disponibility}</Typography>
        <Typography variant="subtitle1">{data.descDispo}</Typography>
      </div>
      <div style={{display:'flex', width:"100%", justifyContent:'end', margin:"10px 0"}}>
        <Button variant="outlined" startIcon={<TimerOutlined />} style={{borderColor:"#000000" }} sx={{color:'black'}} onClick={handleOpen}>Choisir une période</Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
            Si vous souhaitez un remplacement sur un temps long
          </Typography>
          <Typography id="modal-modal-description"  align='center' sx={{ mt: 2 }}>
            Donnez nous vos dates de début de fin
          </Typography>
          <div style={{margin:"10px 0 0 10px"}}>
            {/* <form onSubmit={handleSubmit}> */}
              <div style={{margin:"0px 0 px 0"}}>
                <label>
                  Début :
                  <input type="date" value={startDate} onChange={handleStartDateChange} />
                </label>
              </div>
              <br />
              <div style={{margin:"0px 0 px 0"}}>
                <label>
                  Fin :
                  <input type="date" value={endDate} onChange={handleEndDateChange} />
                </label>
              </div>
              <br />
              <div style={{display:'flex', width:"100%", justifyContent:'end', margin:"10px 0"}}>
                <Button variant='contained' style={{backgroundColor:"#000000" }} sx={{color:'white'}} onClick={handleSubmit}>Valider</Button>
              </div>
            {/* </form> */}
          </div>
        </Box>
      </Modal>
      <Grid container spacing={1}>
      {
        dateWeek && dateWeek.map((elem, index) => (
            <DayCell day={elem.toString()} key={index} index={index} onClick={selectCrenaux} deleteCrenaux={deleteCrenaux}/>
        ))
      }
      </Grid>
    </div>
  )
}

export default Planning;