const form = {
    formField: {
        lastName: {
            name: "lastName",
            label: "Nom ",
            type: "text",
            errorMsg: "Votre nom est requis.",
        },
        firstName: {
            name: "firstName",
            label: "Prénom",
            type: "text",
            errorMsg: "Votre prénom est requis.",
        },
        address: {
            name: "adresse",
            label: "Adresse",
            type: "text",
            errorMsg: "Adresse requise.",
        },
        cityForm: {
            name: "city",
            label: "Ville",
            type: "text",
            errorMsg: "La ville est requis",
        },
        postCodeForm: {
            name: "postCode",
            label: "Code postale",
            type: "number",
            errorMsg: "Code postale requis.",
            invalidMsg: "Code postale est invalide (e.g. 70000).",
        },
        email: {
            name: "mail",
            label: "Adresse Email",
            type: "mail",
            errorMsg: "Votre adresse email est requis.",
            invalidMsg: "Votre adresse email est invalide",
        },
        phoneForm: {
            name: "telephone",
            label: "Téléphone",
            type: "text",
            errorMsg: "Téléphone requis",
            invalidMsg: "Mot de passe incorrecte.",
          },
        materialForm: {
            name: "material",
            label: "Matériel utilisé",
            type: "text",
            errorMsg: "La liste des matériel est requis",
            invalidMsg: "La liste des matériel est requis",
        },
        logicielForm: {
            name: "logiciel",
            label: "Rayon de déplacement",
            type: "text",
            errorMsg: "Rayon de déplacement est requis",
            invalidMsg: "Rayon de déplacement est requis",
        },
        salaireForm: {
            name: "salaire",
            label: "Rémunération souhaité",
            type: "text",
            errorMsg: "Le montant de la rémunération est requis",
            invalidMsg: "Le montant de la rémunération est requis",
        },
        nbPatientForm: {
            name: "nbPatient",
            label: "Moyenne de patient",
            type: "text",
            errorMsg: "La moyenne des patients est requise",
            invalidMsg: "La moyenne des patients est requise",
        },
        diplomeForm: {
            name: "diplome",
            label: "Êtes-vous diplômés ?",
            type: "radio",
            errorMsg: "Le diplôme est requis",
            invalidMsg: "Le diplôme est requis",
        },
        speciality: {
            name: "speciality",
            label: "Spécialité",
            type: "text",
            errorMsg: "Votre spécialité est requise",
            invalidMsg: "Votre spécialité est requise",
        },
        
    }
}

export default form;